import { useContext, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import AuthContext from '../../store/AuthContext';
import InputField from './input-field';
import { sendData } from '../../lib/fetching';
import MyAlert from '../../components/ui/alert';

function ProfilPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const authCtx = useContext(AuthContext);

  const initialValues = {
    oldPass: '',
    newPass: '',
  };

  const validationSchema = {
    oldPass: Yup.string().required('wajib diisi'),
    newPass: Yup.string().required('wajib diisi').min(7, 'minimal 7 karakter'),
  };

  useEffect(() => {
    if (!showAlert) return;

    setTimeout(() => setShowAlert(false), 3000);
  }, [showAlert]);

  return (
    <Box p="3%" w="100%">
      <Box
        w={['100%', null, null, '90%']}
        bg="gray.600"
        p={10}
        color="white"
        borderRadius="lg"
        shadow="lg"
        mb={10}
      >
        <Flex
          w={['100%', null, null, '80%']}
          justify="space-around"
          align="center"
          direction={['column', null, null, 'row']}
        >
          <Flex direction="column" align="center">
            <Avatar size="lg" name={authCtx.name} bg="teal.500" />
            <Text fontSize="sm" mt={2} display={['none', null, null, 'block']}>
              {authCtx.name}
            </Text>
          </Flex>
          <Stack spacing={3} textAlign={['center', null, null, 'left']}>
            <Heading mt={[3, null, null, -3]}>{authCtx.name}</Heading>
            <Text>{authCtx.email}</Text>
          </Stack>
        </Flex>
      </Box>

      <Flex
        justify="center"
        w={['100%', null, null, '50%']}
        // maxW={['100%', null, null, '80%']}
        bg="gray.600"
        color="white"
        p={10}
        borderRadius="lg"
        shadow="lg"
      >
        <Box>
          <Heading as="h2">Ganti Password</Heading>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(validationSchema)}
            onSubmit={(values, { setFieldValue, setFieldError, resetForm }) => {
              setIsLoading(true);

              const data = {
                newPass: values.newPass,
                oldPass: values.oldPass,
              };

              sendData('userchp', data, 'PUT')
                .then(res => {
                  resetForm();
                  setShowAlert(true);
                })
                .catch(error => {
                  setFieldError('oldPass', 'cek password lama anda');
                  setFieldValue('newPass', '', false);
                })
                .finally(() => setIsLoading(false));
            }}
          >
            <Form>
              <Stack spacing={5} mt={5}>
                {showAlert && (
                  <MyAlert
                    title="Berhasil"
                    desc="Alhamdulillah Password anda berhasil diubah"
                    text="black"
                  />
                )}

                {!showAlert && (
                  <>
                    <InputField
                      id="oldPass"
                      name="oldPass"
                      type="password"
                      label="Password Lama"
                    />

                    <InputField
                      id="newPass"
                      name="newPass"
                      type="password"
                      label="Password Baru"
                    />

                    <Button
                      type="submit"
                      // rightIcon={<ArrowForwardIcon />}
                      colorScheme="teal"
                      variant="solid"
                      fontSize="sm"
                      w={['100%', '100%', '90%']}
                      isLoading={isLoading}
                      loadingText="Proses..."
                    >
                      Ganti Password
                    </Button>
                  </>
                )}
              </Stack>
            </Form>
          </Formik>
        </Box>
      </Flex>
    </Box>
  );
}

export default ProfilPage;
