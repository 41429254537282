import { useState, useEffect } from 'react';

import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';

function Search({ setSearch }) {
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearch(searchInput);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchInput, setSearch]);

  return (
    <Box>
      <InputGroup
        bgColor="#fff"
        border="none"
        borderColor="#fff"
        borderRadius="10px"
        width="80%"
        mx={5}
      >
        <InputLeftElement
          pointerEvents="none"
          children={<FiSearch color="gray" />}
        />
        <Input
          required
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          type="text"
          placeholder="Search"
          borderRadius="10px"
        />
      </InputGroup>
    </Box>
  );
}

export default Search;
