import { Box, Button, Heading, Image, Stack } from '@chakra-ui/react';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { apiUrl } from '../../lib/Axios';
import React from 'react';

function InfoDetail({ data, setShowDetail }) {
  return (
    <>
      <Stack align="center" my={5} spacing={8}>
        <Image
          boxSize="3xs"
          objectFit="cover"
          src={apiUrl + '/data_img/' + data.image}
          alt={data.title}
          borderRadius="lg"
        />
        <Heading as="h2">{data.title}</Heading>
        <Box px={5} dangerouslySetInnerHTML={{ __html: data.body }} />
        <Button
          my={3}
          variant="outline"
          colorScheme="blue"
          leftIcon={<MdOutlineKeyboardBackspace />}
          onClick={() => setShowDetail(false)}
        >
          Kembali
        </Button>
      </Stack>
    </>
  );
}

export default InfoDetail;
