import { useState } from 'react';
import { useSWRConfig } from 'swr';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { MdOutlineEdit, MdDeleteOutline } from 'react-icons/md';

import UserForm from './user-form';
import { sendData } from '../../../lib/fetching';

function Users({ data }) {
  const [showUserForm, setShowUserForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const { mutate } = useSWRConfig();

  const editHandler = user => {
    setEditData(user);
    setShowUserForm(false);
    setIsEdit(true);
  };

  const deleteHandler = () => {
    setDeleteIsLoading(true);
    sendData(`users/${deleteId}`, null, 'DELETE')
      .then(res => {
        setDeleteId(null);
        mutate('users');
        onClose();
      })
      .catch(e => console.log(e))
      .finally(() => setDeleteIsLoading(false));
  };

  return (
    <>
      <Text fontSize="lg" mt={10} mb={2}>
        Users Page
      </Text>
      {!isEdit && (
        <Button
          colorScheme="blackAlpha"
          onClick={() => setShowUserForm(!showUserForm)}
        >
          {!showUserForm ? 'Tambah +' : 'Tutup'}
        </Button>
      )}

      {showUserForm && <UserForm showForm={setShowUserForm} mutate={mutate} />}

      {isEdit && (
        <UserForm data={editData} setData={setIsEdit} mutate={mutate} />
      )}

      {!showUserForm && !isEdit && (
        <TableContainer>
          <Table variant="striped" colorScheme="gray">
            <TableCaption placement="top">Daftar User</TableCaption>
            <Thead>
              <Tr>
                <Th>Nama</Th>
                <Th>email</Th>
                <Th>Status</Th>
                <Th isNumeric>saldo</Th>
                <Th>Aksi</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((user, idx) => (
                <Tr key={idx}>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.admin ? 'admin' : ''}</Td>
                  <Td isNumeric>
                    {user.admin
                      ? '-'
                      : new Intl.NumberFormat('id-ID').format(user.saldo)}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      rightIcon={<MdOutlineEdit />}
                      variant="outline"
                      colorScheme="blue"
                      onClick={() => editHandler(user)}
                    >
                      Edit
                    </Button>

                    {!user.admin && (
                      <Button
                        size="sm"
                        mx={3}
                        rightIcon={<MdDeleteOutline />}
                        variant="outline"
                        colorScheme="red"
                        onClick={() => {
                          setDeleteId(user.id);
                          onOpen();
                        }}
                      >
                        Hapus
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={['xs', null, null, null, 'md']}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>Hapus User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Yakin mau hapus?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={deleteHandler}
              isLoading={deleteIsLoading}
            >
              Hapus
            </Button>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Users;
