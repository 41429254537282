import axios from 'axios';

// export const apiUrl = 'http://localhost:8000';
export const apiUrl = 'https://dev.nidautamapadang.id/';

const instance = axios.create({
  baseURL: apiUrl,
});

instance.defaults.withCredentials = true;

export default instance;
