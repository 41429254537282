import { Navigate, Routes, Route } from 'react-router-dom';

import Layout from './components/ui/Layout/Layout';
import Auth from './pages/auth';
import Dashboard from './pages/dashboard';
import ProfilPage from './pages/profil';
import WithDrawPage from './pages/withdraw';
import UsersPage from './pages/users';
import InfosPage from './pages/info';
import InfoPage from './pages/info/info';
import ProtectRoute from './components/protect-route';
import ProtectAuth from './components/protect-route/protect-auth';
import ProtectAdm from './components/protect-route/protect-adm';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="auth" />} />

      <Route element={<ProtectAuth />}>
        <Route path="auth" element={<Auth />} />
      </Route>

      <Route element={<ProtectRoute />}>
        <Route element={<Layout />}>
          <Route element={<ProtectAdm />}>
            <Route path="users" element={<UsersPage />} />
            <Route path="infos" element={<InfosPage />} />
          </Route>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profil" element={<ProfilPage />} />
          <Route path="withdraw" element={<WithDrawPage />} />

          <Route path="info" element={<InfoPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
