import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import AuthContext from '../../store/AuthContext';

function ProtectRoute() {
  const location = useLocation();

  const { isLoggedIn } = useContext(AuthContext);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} replace />
  );
}

export default ProtectRoute;
