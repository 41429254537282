import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Stack, Text } from '@chakra-ui/react';

import {
  authLoginValidatorSchema,
  authRegisterValidatorSchema,
} from '../../lib/validator';
import TextInput from '../ui/form/text-input';
import { sendData } from '../../lib/fetching';
import AuthContext from '../../store/AuthContext';

function AuthForm({ isAuthLogin, setIsAuthLogin, setShowAlert }) {
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const initVal = {
    name: '',
    email: '',
    password: '',
    passConfirm: '',
  };

  return (
    <Formik
      initialValues={initVal}
      validationSchema={
        isAuthLogin ? authLoginValidatorSchema : authRegisterValidatorSchema
      }
      onSubmit={async (values, { setFieldError, setFieldValue, resetForm }) => {
        setIsLoading(true);

        const fetchAPI = isAuthLogin ? 'login' : 'register';

        const dataInput = {
          name: values.name,
          email: values.email,
          password: values.password,
          password_confirmation: values.passConfirm,
        };

        // Login
        if (isAuthLogin) {
          try {
            const res = await sendData(fetchAPI, dataInput);
            setIsLoading(false);
            resetForm();
            authCtx.login(res.data);
            navigate(from, { replace: true });
          } catch (error) {
            setIsLoading(false);
            if (error.status === 401) {
              setFieldError('email', 'cek email anda');
              setFieldError('password', 'cek password');
              setFieldValue('password', '', false);
            }
          }

          // Register
        } else {
          try {
            await sendData(fetchAPI, dataInput);

            setIsLoading(false);
            resetForm();
            setShowAlert(true);
            setIsAuthLogin(true);
          } catch (error) {
            setIsLoading(false);

            if (error.status === 401) {
              setFieldError('email', error.message);
              setFieldError('name', error.message);
            }
            setFieldValue('password', '', false);
            setFieldValue('passConfirm', '', false);
          }
        }
      }}
    >
      <Form>
        <Stack spacing={3}>
          {!isAuthLogin && (
            <TextInput
              name="name"
              placeholder="Nama"
              _placeholder={{ color: 'blue', fontWeight: 'bold' }}
            />
          )}
          <TextInput
            name="email"
            placeholder="Email"
            _placeholder={{ color: 'blue', fontWeight: 'bold' }}
          />
          <TextInput
            name="password"
            placeholder="Password"
            type="password"
            _placeholder={{ color: 'blue', fontWeight: 'bold' }}
          />
          {!isAuthLogin && (
            <TextInput
              name="passConfirm"
              placeholder="Ulangi password"
              type="password"
              _placeholder={{
                color: 'blue',
                fontWeight: 'bold',
              }}
            />
          )}
          <Button
            type="submit"
            isLoading={isLoading}
            w="100%"
            colorScheme="blue"
            mb={3}
            size="md"
            p={3}
          >
            {isAuthLogin ? 'Login' : 'Daftar'}
          </Button>

          <Text
            bgColor="white"
            fontWeight="bold"
            textAlign="center"
            borderRadius="lg"
            py={1}
          >
            {isAuthLogin ? 'Belum punya akun?' : 'Sudah terdaftar?'}{' '}
            <Button
              type="reset"
              colorScheme="blue"
              variant="link"
              onClick={() => setIsAuthLogin(!isAuthLogin)}
            >
              {isAuthLogin ? 'Daftar disini' : 'Login'}
            </Button>
          </Text>
        </Stack>
      </Form>
    </Formik>
  );
}

export default AuthForm;
