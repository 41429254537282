import React from 'react';
// import ReactDOM from 'react-dom/client';
import { hydrate, render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

import App from './App';
import { AuthContextProvider } from './store/AuthContext';

const theme = extendTheme({
  fonts: {
    heading: 'poppins',
    body: 'poppins',
  },
});

// const root = ReactDOM.createRoot(document.getElementById('root'));
const root = document.getElementById('root');

if (root.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <AuthContextProvider>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ChakraProvider>
      </AuthContextProvider>
    </BrowserRouter>,
    root
  );
} else {
  render(
    <BrowserRouter>
      <AuthContextProvider>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ChakraProvider>
      </AuthContextProvider>
    </BrowserRouter>,
    root
  );
}

// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <AuthContextProvider>
//         <ChakraProvider theme={theme}>
//           <Routes>
//             <Route path="/*" element={<App />} />
//           </Routes>
//         </ChakraProvider>
//       </AuthContextProvider>
//     </BrowserRouter>
//   </React.StrictMode>
// );
