import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Center,
  Divider,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MdDateRange } from 'react-icons/md';

import { apiUrl } from '../../lib/Axios';
import InfoDetail from './InfoDetail';

function Info({ data }) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <>
      <Center>
        <Heading>Info Nida Utama</Heading>
      </Center>

      {data?.length === 0 && <Text fontSize="md">Belum ada info</Text>}

      {showDetail && (
        <InfoDetail data={showDetail} setShowDetail={setShowDetail} />
      )}

      {!showDetail && (
        <SimpleGrid
          my={3}
          columns={[null, null, null, 2, 3]}
          spacing={10}
          boxShadow="md"
          p={3}
        >
          {data?.map(info => (
            <Card maxW="sm" key={info.id} boxShadow="xl">
              <CardBody>
                <Image
                  w="100%"
                  maxH="180px"
                  objectFit="cover"
                  src={apiUrl + '/data_img/' + info.image}
                  alt={info.title}
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">{info.title}</Heading>
                  <Text
                    noOfLines={3}
                    dangerouslySetInnerHTML={{ __html: info.body }}
                  />
                  <Stack direction="row" spacing={3} align="center">
                    <MdDateRange />
                    <Text color="blue.600" fontSize="sm">
                      {new Date(info.created_at).toLocaleDateString('id-ID', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Text>
                  </Stack>
                </Stack>
              </CardBody>
              <Divider />
              <CardFooter>
                <ButtonGroup spacing="2">
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={() => setShowDetail(info)}
                  >
                    Selengkapnya
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
      )}
    </>
  );
}

export default Info;
