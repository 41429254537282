import { useState, useRef, useEffect, useContext } from 'react';
import {
  Flex,
  Heading,
  Text,
  Icon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  FormControl,
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { FiCreditCard } from 'react-icons/fi';
import CurrencyInput from 'react-currency-input-field';

import { sendData } from '../../../lib/fetching';
import MyAlert from '../../ui/alert';
import AuthContext from '../../../store/AuthContext';

function Right({ total, trigger, card, totalAll }) {
  const [value, changeValue] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showBtnSendWa, setShowBtnSendWa] = useState(false);
  const [enteredInput, setEnteredInput] = useState('');

  const authCtx = useContext(AuthContext);

  const noTransInputRef = useRef();

  const cardNum = card?.split('-') || 0;

  const cards = [
    { grad: 'linear(to-t, #B57295, #29259A)' },
    { grad: 'linear(to-t, yellow.300, blue.500)' },
    { grad: 'linear(to-t, orange.300, pink.600)' },
  ];

  const noRek = {
    bank: 'BSI',
    nomor: '7209102379',
    an: 'PT Nida utama Sejahtera Cabang Padang',
  };

  useEffect(() => {
    if (!showAlert) return;

    setTimeout(() => setShowAlert(false), 8000);
  }, [showAlert]);

  const topUpHandler = () => {
    const noTrans = noTransInputRef.current.value;

    setIsError(false);

    if (
      !enteredInput ||
      enteredInput.trim() === '' ||
      enteredInput <= 0 ||
      !noTrans
    ) {
      return;
    }

    setIsButtonLoading(true);

    const data = {
      amount: +enteredInput,
      no_trans: noTrans,
      category: 'Top Up',
      name: 'Tabungan Umroh',
      noRek,
    };

    sendData('transaksi', data, 'POST')
      .then(res => {
        setIsButtonLoading(false);
        setShowAlert(true);
        trigger(true);
        setEnteredInput('');
        setShowBtnSendWa(res.data);
      })
      .catch(e => {
        setIsButtonLoading(false);
        if (e.status === 422 && e.message.includes('no trans')) {
          setIsError(true);
        }
        // console.log(e);
      });
  };

  const openNewTab = url => window.open(url, '_blank').focus();

  const sendWaHandler = data => {
    const msg = `Assalamu'alaikum. Saya *${authCtx.name}*,
    telah melakukan transaksi top up *${data.data.name}* dengan ID transaksi *${
      data.data.no_trans
    }* dengan jumlah *Rp.${new Intl.NumberFormat('id-ID').format(
      data.data.amount
    )}*. Dan berikut saya sertakan bukti transfernya :`;

    const url = `https://api.whatsapp.com/send?phone=62${data.noAdmin}&text=${msg}`;

    openNewTab(url);
    setShowBtnSendWa(false);
  };

  return (
    <Flex
      w={['100%', '100%', '30%']}
      h="100vh"
      bgColor="#F5F5F5"
      p="3%"
      flexDir="column"
      overflow="auto"
      minW={[null, null, '300px', '300px', '400px']}
    >
      <Heading letterSpacing="tight">My Cards</Heading>
      <Box
        borderRadius="25px"
        mt={4}
        w="100%"
        h="200px"
        bgGradient={cards[value - 1].grad}
      >
        <Flex
          p="1em"
          color="#fff"
          flexDir="column"
          h="100%"
          justify="space-between"
        >
          <Flex justify="space-between" w="100%" align="flex-start">
            <Flex flexDir="column">
              <Text color="gray.400">Current Balance</Text>
              <Text fontWeight="bold" fontSize="xl">
                {`Rp. ${totalAll || 0}`}
                {/* {`Rp. ${total || 0}`} */}
              </Text>
            </Flex>
            <Flex align="center">
              <Icon mr={2} as={FiCreditCard} />
              <Text>Nida</Text>
            </Flex>
          </Flex>
          <Text mb={4}>{`**** **** **** ${cardNum[3] || 0}`}</Text>
          <Flex align="flex-end" justify="space-between">
            <Flex>
              <Flex flexDir="column" mr={4}>
                <Text textTransform="uppercase" fontSize="xs">
                  Valid Thru
                </Text>
                <Text fontSize="lg">12/23</Text>
              </Flex>
              <Flex flexDir="column">
                <Text textTransform="uppercase" fontSize="xs">
                  CVV
                </Text>
                <Text fontSize="lg">***</Text>
              </Flex>
            </Flex>
            <Icon as={FiCreditCard} />
          </Flex>
        </Flex>
      </Box>

      <Flex justifyContent="center" mt={2}>
        <Button
          bgColor={value === 1 ? 'gray.600' : 'gray.400'}
          size="xs"
          mx={1}
          onClick={() => changeValue(1)}
        />
        <Button
          bgColor={value === 2 ? 'gray.600' : 'gray.400'}
          size="xs"
          mx={1}
          onClick={() => changeValue(2)}
        />
        <Button
          bgColor={value === 3 ? 'gray.600' : 'gray.400'}
          size="xs"
          mx={1}
          onClick={() => changeValue(3)}
        />
      </Flex>

      {showAlert && (
        <MyAlert
          topup="true"
          title="Transaksi berhasil!"
          desc="Terima kasih. Insya Allah tim kami akan segera memproses transaksi
        bapak/ibu."
        />
      )}
      {showBtnSendWa && (
        <Button
          mt={3}
          colorScheme="whatsapp"
          onClick={() => sendWaHandler(showBtnSendWa)}
        >
          Kirim bukti
        </Button>
      )}
      {!showAlert && !showBtnSendWa && (
        <>
          <Card mt={5}>
            <CardHeader>
              <Heading size="md">Rekening Top Up</Heading>
            </CardHeader>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {noRek.bank}
                  </Heading>
                  <Text pt="2" fontSize="sm" fontWeight="bold">
                    {noRek.nomor}
                  </Text>
                  <Text pt="2" fontSize="sm" fontWeight="bold">
                    {`a/n ${noRek.an}`}
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>

          <Text fontSize="lg" mt={5} mb={2}>
            Top Up
          </Text>
          <Text color="gray" mt={4} mb={2}>
            Jumlah
          </Text>
          <InputGroup>
            <CurrencyInput
              customInput={Input}
              placeholder="Rp."
              allowNegative="false"
              allowEmpty="false"
              intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
              onValueChange={value => setEnteredInput(value)}
            />
          </InputGroup>
          <Text color="gray" mt={4} mb={2}>
            No. Transaksi / Referensi
          </Text>
          <FormControl isInvalid={isError}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={'No.'} />
              <Input
                ref={noTransInputRef}
                type="text"
                placeholder="Nomor Transaksi"
              />
            </InputGroup>
            {isError && (
              <FormErrorMessage>
                No. transaksi ini sudah pernah diinput
              </FormErrorMessage>
            )}
          </FormControl>
          <Button
            onClick={topUpHandler}
            mt={4}
            colorScheme="green"
            p={7}
            borderRadius={15}
            isLoading={isButtonLoading}
          >
            Top Up
          </Button>
        </>
      )}
    </Flex>
  );
}

export default Right;
