import { string, ref, object } from 'yup';

export const authLoginValidatorSchema = object({
  email: string().email('Email tidak valid').required('Email wajib diisi'),
  password: string().required('Password wajib diisi'),
});

export const authRegisterValidatorSchema = object({
  name: string().required('Nama wajib diisi'),
  email: string().email('Email tidak valid').required('Email wajib diisi'),
  password: string()
    .required('Password wajib diisi')
    .min(7, 'Minimal 7 karakter'),
  passConfirm: string()
    .required('Konfirmasi password wajib diisi')
    .oneOf([ref('password'), null], 'password tidak sama'),
});
