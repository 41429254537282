import { useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';

function TextInput({ label, ...props }) {
  const [field, meta] = useField(props);

  const isInvalid = meta.touched && meta.error;

  const mbInput = !isInvalid ? { mb: 3 } : null;

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        {...field}
        {...props}
        _focus={{ bg: 'white' }}
        fontWeight="bold"
        variant="filled"
        isInvalid={isInvalid}
        {...mbInput}
      />

      {isInvalid && (
        <FormHelperText mb={3} color="red.500">
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default TextInput;
