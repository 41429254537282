import { useState, useEffect } from 'react';

import Main from '../components/dashboard/main';
import { sendData } from '../lib/fetching';
import { spinner } from '../lib/swr-utils';

export default function Dashboard() {
  const [filterDate, setFilterDate] = useState({ start: null, end: null });
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!trigger) return;

    setTrigger(false);
  }, [trigger]);

  useEffect(() => {
    sendData(
      `transaksi?search=${search}&startDate=${
        filterDate.start?.toISOString() || ''
      }&endDate=${filterDate.end?.toISOString() || ''}`,
      null,
      'GET'
    ).then(res => {
      // console.log(res);
      setIsLoading(false);
      setData(res.data);
    });
  }, [search, filterDate, trigger]);

  return (
    <>
      {/* {isError && errorText(isError.message)} */}
      {isLoading && spinner}
      {!isLoading && (
        <Main
          data={data}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
          setSearch={setSearch}
          setTrigger={setTrigger}
        />
      )}
    </>
  );
}
