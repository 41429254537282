import { useState, useEffect } from 'react';
import { Center, Flex, Image } from '@chakra-ui/react';

import AuthForm from '../components/auth/auth-form';
import MyAlert from '../components/ui/alert';
// import AlertContext from '../store/AlertContext';

function Auth() {
  const [isAuthLogin, setIsAuthLogin] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!showAlert) return;

    setTimeout(() => setShowAlert(false), 3000);
  }, [showAlert]);

  return (
    <Flex
      h="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundImage="url('background.jpg')"
      backgroundPosition="center"
      bgSize="cover"
      p={['3', null, null, 0]}
    >
      <Flex
        w={['100%', null, null, '30%']}
        direction="column"
        p={8}
        rounded={6}
        bg="gray.700"
        // bgGradient="radial(gray.300, teal.200, blue.200)"
        // blur="100px"
        opacity="70%"
      >
        <Center mb={5}>
          <Image
            src="/logo.jpg"
            alt="logo nidautama"
            borderRadius="full"
            boxSize="80px"
          />
        </Center>

        {showAlert && (
          <MyAlert
            title="Berhasil"
            desc="Alhamdulillah akun anda sudah terdaftar. Silahkan login"
          />
        )}
        <AuthForm
          isAuthLogin={isAuthLogin}
          setIsAuthLogin={setIsAuthLogin}
          setShowAlert={setShowAlert}
        />
      </Flex>
    </Flex>
  );
}

export default Auth;
