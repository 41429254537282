import { useState } from 'react';
import { Button, Stack } from '@chakra-ui/react';
import { sendData } from '../../lib/fetching';

function ActionButton({ data, trigger }) {
  const [isButtonApproveLoading, setIsButtonApproveLoading] = useState(false);
  const [isButtonRejectLoading, setIsButtonRejectLoading] = useState(false);

  const approveHandler = () => {
    setIsButtonApproveLoading(true);

    sendData(`transaksi/appr/${data.id}`, null, 'PUT')
      .then(res => {
        setIsButtonApproveLoading(false);
        trigger(true);
        // console.log(res);
      })
      .catch(e => {
        setIsButtonApproveLoading(false);
        // console.error(e);
      });
  };

  const rejectHandler = () => {
    setIsButtonRejectLoading(true);

    sendData(`transaksi/reject/${data.id}`, null, 'PUT')
      .then(res => {
        setIsButtonRejectLoading(false);
        trigger(true);
      })
      .catch(e => {
        setIsButtonRejectLoading(false);
        // console.error(e);
      });
  };

  return (
    <Stack>
      <Button isLoading={isButtonApproveLoading} onClick={approveHandler}>
        Approve
      </Button>
      <Button isLoading={isButtonRejectLoading} onClick={rejectHandler}>
        Reject
      </Button>
    </Stack>
  );
}

export default ActionButton;
