import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';

import Form from './form';

function MyPopover({ setLoading, setTransaksi, setFilterDate, icon }) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <IconButton icon={icon} />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverArrow />
        <PopoverCloseButton />
        <Form
          onCancel={onClose}
          setLoading={setLoading}
          setTransaksi={setTransaksi}
          setDate={setFilterDate}
        />
      </PopoverContent>
    </Popover>
  );
}

export default MyPopover;
