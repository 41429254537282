import { Box } from '@chakra-ui/react';

import { useFetchWithSwr, errorText, spinner } from '../../lib/swr-utils';
import Users from '../../components/admin/users';

function UsersPage() {
  const { data, isLoading, isError } = useFetchWithSwr('users');

  return (
    <Box p="3%" w="100%">
      {isError && errorText(isError.message)}
      {isLoading && spinner}
      {data && <Users data={data?.data} />}
    </Box>
  );
}

export default UsersPage;
