import { Box } from '@chakra-ui/react';

import { useFetchWithSwr, errorText, spinner } from '../../lib/swr-utils';
import Info from '../../components/info/Info';

function InfoPage() {
  const { data, isLoading, isError } = useFetchWithSwr('infos');

  return (
    <Box p="3%" w="100%">
      {isError && errorText(isError.message)}
      {isLoading && spinner}
      {data && <Info data={data?.data} />}
    </Box>
  );
}

export default InfoPage;
