import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Image,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useSWRConfig } from 'swr';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MdOutlineSave, MdOutlineCancel } from 'react-icons/md';

import { sendData } from '../../../lib/fetching';

const modules = {
  toolbar: [
    [
      {
        header: [1, 2, 3, 4, 5, 6, false],
      },
    ],
    [{ font: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
  ],
};

function AddInfoForm({
  showForm,
  initTitle,
  initContent,
  infoId,
  img,
  url,
  ...props
}) {
  const [content, setContent] = useState(initContent || '');
  const [title, setTitle] = useState(initTitle || '');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [file, setFile] = useState();
  const { mutate } = useSWRConfig();

  const imgUrl = url + '/data_img/' + img;

  const uploadHandler = event => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setFile(formData);
  };

  const submitHandler = () => {
    if (!title || !content) return;
    if (!props.isEdit && !file) return;

    setIsButtonLoading(true);

    let data;

    if (file?.has('file')) {
      file.append('title', title);
      file.append('content', content);
      if (props.isEdit) file.append('_method', 'PUT');
    } else {
      data = {
        title,
        content,
        _method: 'PUT',
      };
    }

    const fixData = file?.has('file') ? file : data;
    const url = !props.isEdit ? 'infos' : `infos/${infoId}`;

    sendData(url, fixData, 'POST')
      .then(res => {
        // console.log(res);
        setIsButtonLoading(false);
        mutate('infos');
        props.isEdit ? props.isEdit(false) : showForm(false);
      })
      .catch(e => {
        setIsButtonLoading(false);
      });
  };

  return (
    <Stack w={['100%', null, null, '80%']} spacing={5}>
      <FormControl my={3}>
        <FormLabel>Judul</FormLabel>
        <Input
          borderColor="blue.500"
          value={title}
          onChange={e => setTitle(e.target.value)}
          type="text"
          required
        />
      </FormControl>
      <FormControl>
        <FormLabel>Konten</FormLabel>
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          modules={modules}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Gambar</FormLabel>
        {props.isEdit && (
          <Image my={3} boxSize="100px" src={imgUrl} alt={title} />
        )}
        <Input type="file" onChange={uploadHandler} />
      </FormControl>
      <ButtonGroup variant="outline" spacing="6">
        <Button
          colorScheme="blue"
          isLoading={isButtonLoading}
          onClick={submitHandler}
          rightIcon={<MdOutlineSave />}
        >
          {!props.isEdit ? 'Tambah' : 'Edit'}
        </Button>
        {props.isEdit && (
          <Button
            colorScheme="red"
            onClick={() => props.isEdit(false)}
            rightIcon={<MdOutlineCancel />}
          >
            Batal
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
}

export default AddInfoForm;
