import React from 'react';
// eslint-disable-next-line no-unused-vars
import _ from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

const options = {
  maintainAspectRatio: true,
  scales: {
    x: {
      grid: {
        display: true,
      },
    },
    y: {
      grid: {
        borderDash: [3, 3],
      },
      beginAtZero: true, // this works
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const MyChart = props => {
  const myData = props.data.map(data => data.amount || 0);
  const currentMonth = new Date().getMonth();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ];

  const myMonth = months.filter((_, idx) => idx <= currentMonth);

  // console.log(myMonth, currentMonth);

  const data = {
    labels: myMonth,
    datasets: [
      {
        label: 'Top Up',
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#db86b2',
        borderColor: '#B57295',
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        borderJoinStyle: '#B57295',
        pointBorderColor: '#B57295',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#B57295',
        pointHoverBorderColor: '#B57295',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: myData,
      },
    ],
  };

  return <Line data={data} options={options} />;
};

export default MyChart;
