import { useState, useEffect } from 'react';
import { Box, Button, Input, InputGroup, Text } from '@chakra-ui/react';
import CurrencyInput from 'react-currency-input-field';

import { sendData } from '../../lib/fetching';
import { makeRand } from '../../lib/str';
import MyAlert from '../../components/ui/alert';

function WithDrawPage() {
  const [showAlert, setShowAlert] = useState(false);
  const [isError, setIsError] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [enteredInput, setEnteredInput] = useState('');

  useEffect(() => {
    if (!showAlert) return;

    setTimeout(() => setShowAlert(false), 5000);
  }, [showAlert]);

  useEffect(() => {
    if (!isError) return;

    setTimeout(() => setIsError(false), 5000);
  }, [isError]);

  const wdHandler = () => {
    if (!enteredInput || enteredInput <= 0) return;

    const noTrans = makeRand(5);

    setIsButtonLoading(true);

    const data = {
      amount: +enteredInput,
      no_trans: noTrans,
      category: 'withdraw',
      name: 'Penarikan',
    };

    sendData('transaksi?wd=1', data, 'POST')
      .then(res => {
        // console.log(res);
        setIsButtonLoading(false);
        setShowAlert(true);
        setEnteredInput('');
      })
      .catch(e => {
        setIsButtonLoading(false);
        setIsError(e.message);
      });
  };

  return (
    <Box p="3%" w="120%">
      {showAlert && (
        <MyAlert
          title="Permintaan berhasil!"
          desc="Terima kasih. Insya Allah tim kami akan segera memproses transaksi
        bapak/ibu."
        />
      )}

      {isError && <MyAlert error="error" title="Gagal!" desc={isError} />}

      {!showAlert && !isError && (
        <>
          <Text fontSize="lg" mt={10} mb={2}>
            Form Pengajuan Penarikan Saldo
          </Text>
          <Text color="gray.600" mt={4} mb={2}>
            Jumlah
          </Text>
          <InputGroup w="50%">
            <CurrencyInput
              customInput={Input}
              placeholder="Rp."
              allowNegative="false"
              allowEmpty="false"
              intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
              onValueChange={value => setEnteredInput(value)}
            />
          </InputGroup>
          <Button
            onClick={wdHandler}
            mt={4}
            colorScheme="green"
            p={7}
            borderRadius={15}
            isLoading={isButtonLoading}
          >
            Kirim
          </Button>
        </>
      )}
    </Box>
  );
}

export default WithDrawPage;
