import { Outlet } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import Sidebar from './sidebar';
import SidebarMobile from './sidebar-mobile';

function Layout() {
  return (
    <Flex
      minH={[null, null, '100vh']}
      maxW="2000px"
      flexDir={['column', 'column', 'row']}
      // overflowX="hidden"
    >
      <Sidebar />
      <SidebarMobile />
      <Outlet />
    </Flex>
  );
}

export default Layout;
