import { Center, CircularProgress, Text } from '@chakra-ui/react';
import useSWR from 'swr';

import { sendData } from './fetching';

const fetcher = url => sendData(url, null, 'GET');

export const useFetchWithSwr = apiUrl => {
  const { data, error } = useSWR(apiUrl, fetcher, { revalidateOnFocus: false });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const spinner = (
  <Center>
    <CircularProgress isIndeterminate value={30} size="70px" />
  </Center>
);

export const errorText = error => (
  <Center>
    <Text color="orange.500" fontWeight="semibold" fontSize="lg">
      {error}
    </Text>
  </Center>
);
