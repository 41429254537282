import { useState, useContext } from 'react';
import {
  Flex,
  Text,
  IconButton,
  Divider,
  Avatar,
  Heading,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverFooter,
  Button,
} from '@chakra-ui/react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiHome,
  FiUser,
  FiUsers,
  FiBriefcase,
} from 'react-icons/fi';
import NavItem from './nav-item';
import AuthContext from '../../../store/AuthContext';

export default function Sidebar() {
  const [navSize, changeNavSize] = useState('large');
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    setIsLoading(true);
    authCtx.logout();
  };

  return (
    <Flex
      display={['none', 'none', 'flex']}
      pos="sticky"
      left="5"
      mr={3}
      my="2.5vh"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      borderRadius={navSize === 'small' ? '15px' : '30px'}
      w={navSize === 'small' ? '75px' : '300px'}
      flexDir="column"
      justifyContent="space-between"
      backgroundColor="#020202"
      color="#fff"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        as="nav"
      >
        <IconButton
          background="none"
          mt={5}
          _hover={{ background: 'none' }}
          icon={navSize === 'small' ? <FiChevronRight /> : <FiChevronLeft />}
          onClick={() => {
            if (navSize === 'small') changeNavSize('large');
            else changeNavSize('small');
          }}
        />
        <NavItem
          navSize={navSize}
          icon={FiHome}
          title="Home"
          url="/dashboard"
          description="This is the description for the dashboard."
        />
        <NavItem navSize={navSize} icon={FiUser} title="Profil" url="/profil" />
        {/* {!authCtx.isAdmin && (
          <NavItem
            navSize={navSize}
            icon={FiDollarSign}
            title="Tarik Saldo"
            url="/withdraw"
          />
        )} */}
        {!authCtx.isAdmin && (
          <NavItem
            navSize={navSize}
            icon={FiBriefcase}
            title="Info"
            url="/info"
          />
        )}
        {authCtx.isAdmin && (
          <>
            <NavItem
              navSize={navSize}
              icon={FiUsers}
              title="User"
              url="/users"
            />
            <NavItem
              navSize={navSize}
              icon={FiBriefcase}
              title="Info"
              url="/infos"
            />
          </>
        )}
      </Flex>

      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        mb={4}
      >
        <Divider display={navSize === 'small' ? 'none' : 'flex'} />
        <Flex mt={4} align="center">
          <Popover placement="right" closeOnBlur={true}>
            <PopoverTrigger>
              <Flex mt={4} align="center">
                <Avatar size="sm" src="logo.jpg" />
                <Flex
                  flexDir="column"
                  ml={4}
                  display={navSize === 'small' ? 'none' : 'flex'}
                >
                  <Heading as="h3" size="sm">
                    {authCtx.name}
                  </Heading>
                  {authCtx.isAdmin && <Text color="gray">Admin</Text>}
                </Flex>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              color="white"
              bg="blue.800"
              borderColor="blue.800"
              w="100%"
            >
              <PopoverHeader pt={5} fontWeight="bold" border="0">
                Nida Utama
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Button
                  isLoading={isLoading}
                  colorScheme="green"
                  onClick={logoutHandler}
                >
                  Logout
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </Flex>
  );
}
