import { useRef } from 'react';
import { Button, ButtonGroup, Stack } from '@chakra-ui/react';

import TextInput from './text-input';
function Form({ onCancel, setDate }) {
  const startDate = useRef();
  const endDate = useRef();

  const submitHandler = e => {
    e.preventDefault();
    onCancel();

    const start = new Date(startDate.current.value);
    const end = new Date(endDate.current.value);

    setDate(prev => ({ ...prev, start, end }));
  };

  return (
    <form onSubmit={e => submitHandler(e)}>
      <Stack spacing={4}>
        <TextInput ref={startDate} label="Start" id="start-date" type="date" />
        <TextInput ref={endDate} label="End" id="end-date" type="date" />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="teal">
            Search
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
}

export default Form;
