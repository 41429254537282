import AddInfoForm from './addInfo-form';

function EditInfoPage({ data, setIsEdit, apiUrl }) {
  return (
    <AddInfoForm
      infoId={data.id}
      initTitle={data.title}
      initContent={data.body}
      img={data.image}
      url={apiUrl}
      isEdit={setIsEdit}
    />
  );
}

export default EditInfoPage;
