import { useContext, useState } from 'react';
import { Link as MyLink } from 'react-router-dom';
import {
  Flex,
  Text,
  Link,
  Icon,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverFooter,
  Button,
  Stack,
} from '@chakra-ui/react';
import { MdDashboard } from 'react-icons/md';
import { FiDollarSign } from 'react-icons/fi';
import { AiOutlineUser, AiOutlineInfoCircle } from 'react-icons/ai';
import AuthContext from '../../../store/AuthContext';

function SidebarMobile() {
  const [isLoading, setIsLoading] = useState(false);
  // const [navSize, changeNavSize] = useState('small');

  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    setIsLoading(true);
    authCtx.logout();
  };

  return (
    <>
      <Flex
        display={['flex', null, 'none']}
        w="100%"
        // w={['100%', '100%', navSize == 'small' ? '75px' : '300px']}
        // w={['100%', '100%', '10%', '15%', '15%']}
        flexDir="column"
        alignItems="center"
        boxShadow="xl"
      >
        <Stack
          spacing={0}
          fontWeight="semibold"
          textAlign="center"
          py={3}
          bg="#B8860B"
          color="gold"
          w="100%"
        >
          <Text>Tabungan Umroh</Text>
          <Text>PT. Nida Utama Sejahtera</Text>
        </Stack>
        <Flex
          bg="#020202"
          color="#fff"
          flexDir="column"
          h={[null, null, '100vh']}
          w="100%"
          // alignItems={navSize == 'small' ? 'center' : 'flex-start'}
          justifyContent="space-between"
        >
          <Flex flexDir="column" as="nav">
            {/* <Image
            src="/nida.jpg"
            alt="logo"
            objectFit="cover"
            mb={25}
            boxSize="100%"
          /> */}

            <Flex
              flexDir={['row', 'row', 'column', 'column', 'column']}
              align={['center', 'center', 'center', 'flex-start', 'flex-start']}
              wrap={['wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap']}
              justifyContent="center"
              pt={3}
            >
              <Flex className="sidebar-items" mr={[2, 6, 0, 0, 0]}>
                <Link display={['none', 'none', 'flex', 'flex', 'flex']}>
                  <Icon
                    as={MdDashboard}
                    fontSize="2xl"
                    className="active-icon"
                  />
                </Link>
                <Link
                  as={MyLink}
                  to="/dashboard"
                  _hover={{ textDecor: 'none' }}
                  display={['flex', 'flex', 'none', 'flex', 'flex']}
                >
                  <Text className="active">Home</Text>
                </Link>
              </Flex>
              <Flex className="sidebar-items" mr={[2, 6, 0, 0, 0]}>
                <Link display={['none', 'none', 'flex', 'flex', 'flex']}>
                  <Icon as={AiOutlineUser} fontSize="2xl" />
                </Link>
                <Link
                  as={MyLink}
                  to="/profil"
                  _hover={{ textDecor: 'none' }}
                  display={['flex', 'flex', 'none', 'flex', 'flex']}
                >
                  <Text>Profile</Text>
                </Link>
              </Flex>

              {authCtx.isAdmin && (
                <Flex className="sidebar-items" mr={[2, 6, 0, 0, 0]}>
                  <Link display={['none', 'none', 'flex', 'flex', 'flex']}>
                    <Icon as={FiDollarSign} fontSize="2xl" />
                  </Link>
                  <Link
                    as={MyLink}
                    to="/users"
                    _hover={{ textDecor: 'none' }}
                    display={['flex', 'flex', 'none', 'flex', 'flex']}
                  >
                    <Text>User</Text>
                  </Link>
                </Flex>
              )}

              {/* {!authCtx.isAdmin && (
              <Flex className="sidebar-items" mr={[2, 6, 0, 0, 0]}>
                <Link display={['none', 'none', 'flex', 'flex', 'flex']}>
                  <Icon as={FiDollarSign} fontSize="2xl" />
                </Link>
                <Link
                  as={MyLink}
                  to="/withdraw"
                  _hover={{ textDecor: 'none' }}
                  display={['flex', 'flex', 'none', 'flex', 'flex']}
                >
                  <Text>Tarik Saldo</Text>
                </Link>
              </Flex>
            )} */}
              {!authCtx.isAdmin && (
                <Flex className="sidebar-items" mr={[2, 6, 0, 0, 0]}>
                  <Link display={['none', 'none', 'flex', 'flex', 'flex']}>
                    <Icon as={AiOutlineInfoCircle} fontSize="2xl" />
                  </Link>
                  <Link
                    as={MyLink}
                    to="/info"
                    _hover={{ textDecor: 'none' }}
                    display={['flex', 'flex', 'none', 'flex', 'flex']}
                  >
                    <Text>Info</Text>
                  </Link>
                </Flex>
              )}

              {authCtx.isAdmin && (
                <Flex className="sidebar-items" mr={[2, 6, 0, 0, 0]}>
                  <Link display={['none', 'none', 'flex', 'flex', 'flex']}>
                    <Icon as={AiOutlineInfoCircle} fontSize="2xl" />
                  </Link>
                  <Link
                    as={MyLink}
                    to="/infos"
                    _hover={{ textDecor: 'none' }}
                    display={['flex', 'flex', 'none', 'flex', 'flex']}
                  >
                    <Text>Info</Text>
                  </Link>
                </Flex>
              )}
            </Flex>
          </Flex>

          <Popover placement="bottom" closeOnBlur={true}>
            <PopoverTrigger>
              <Flex flexDir="column" alignItems="center" mb={10} mt={5}>
                <Avatar my={2} src="logo.jpg" />
                <Text textAlign="center">{authCtx.name}</Text>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              color="white"
              bg="blue.800"
              borderColor="blue.800"
              w="100%"
            >
              <PopoverHeader pt={4} fontWeight="bold" border="0">
                Nida Utama
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverFooter
                border="0"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Button
                  isLoading={isLoading}
                  colorScheme="green"
                  onClick={logoutHandler}
                >
                  Logout
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </>
  );
}

export default SidebarMobile;
