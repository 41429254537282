import { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input } from '@chakra-ui/react';

import { sendData } from '../../../lib/fetching';

function UserForm({ data, setData, showForm, mutate }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [name, setName] = useState(data?.name || '');
  const [email, setEmail] = useState(data?.email || '');

  const generateCardNum = () => Math.floor(1000 + Math.random() * 9000);

  const submitHandler = () => {
    if (!name || !email || !name?.trim() || !email?.trim()) return;

    let cardNum = '';

    for (let i = 0; i < 4; i++) {
      cardNum += i !== 3 ? `${generateCardNum()}-` : generateCardNum();
    }

    setIsButtonLoading(true);

    const dataUser = {
      name,
      email,
      cardNum,
    };

    const url = !data ? 'users' : `users/${data.id}`;
    const method = !data ? 'POST' : 'PUT';

    sendData(url, dataUser, method)
      .then(res => {
        mutate('users');
        if (data) setData(false);
        if (showForm) showForm(false);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => setIsButtonLoading(false));
  };

  const resetHandler = () => {
    setName('');
    setEmail('');
  };

  return (
    <Box>
      <FormControl my={3}>
        <FormLabel>Nama</FormLabel>
        <Input
          value={name}
          onChange={e => setName(e.target.value)}
          type="text"
          required
        />
      </FormControl>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          required
        />
      </FormControl>
      <Button
        my={3}
        variant="outline"
        colorScheme="blue"
        onClick={submitHandler}
        isLoading={isButtonLoading}
      >
        {!data ? 'Tambah' : 'Edit'}
      </Button>

      <Button
        my={3}
        mx={3}
        variant="outline"
        colorScheme="blue"
        onClick={!data ? resetHandler : () => setData(false)}
      >
        {!data ? 'Reset' : 'Batal'}
      </Button>
    </Box>
  );
}

export default UserForm;
