import { useState } from 'react';
import {
  Button,
  Image,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { MdEdit, MdAdd, MdClose } from 'react-icons/md';

import AddInfoForm from './addInfo-form';
import EditInfoPage from './edit';
import { apiUrl } from '../../../lib/Axios';

function Infos({ data }) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});

  return (
    <>
      <Text fontSize="lg" mt={10} mb={2}>
        {!isEdit ? 'Halaman Info' : 'Edit Info'}
      </Text>

      {isEdit && (
        <EditInfoPage data={editData} setIsEdit={setIsEdit} apiUrl={apiUrl} />
      )}

      {!isEdit && (
        <>
          <Button
            colorScheme="blue"
            rightIcon={!showAddForm ? <MdAdd /> : <MdClose />}
            onClick={() => setShowAddForm(!showAddForm)}
          >
            {!showAddForm ? 'Tambah' : 'Tutup'}
          </Button>

          {showAddForm && <AddInfoForm showForm={setShowAddForm} />}
          <TableContainer whiteSpace="pre-wrap" maxWidth="100%">
            <Table variant="striped" colorScheme="gray">
              <TableCaption placement="top">Info Terbaru</TableCaption>
              <Thead>
                <Tr>
                  <Th>Judul</Th>
                  <Th>Konten</Th>
                  <Th>image</Th>
                  <Th>Aksi</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((info, idx) => (
                  <Tr key={idx}>
                    <Td>{info.title}</Td>
                    <Td>
                      <Text
                        noOfLines={1}
                        dangerouslySetInnerHTML={{ __html: info.body }}
                      />
                    </Td>
                    <Td>
                      <Image
                        boxSize="50px"
                        objectFit="cover"
                        src={apiUrl + '/data_img/' + info.image}
                        alt={info.title}
                      />
                    </Td>
                    <Td>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        rightIcon={<MdEdit />}
                        onClick={() => {
                          setEditData(info);
                          setIsEdit(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

export default Infos;
