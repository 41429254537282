import { useState, useEffect, useContext } from 'react';
import {
  Flex,
  Heading,
  Avatar,
  Text,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Stack,
  Badge,
} from '@chakra-ui/react';
import { FiCalendar, FiChevronDown, FiChevronUp } from 'react-icons/fi';

import MyChart from '../MyChart';
import Right from './right-side';
import MyPopover from './popover/mypopover';
import { sendData } from '../../lib/fetching';
import Search from './search/search';
import { formatCurr, formatDate } from '../../lib/format-num-date';
import AuthContext from '../../store/AuthContext';
import ActionButton from './aksi';

function Main({ isLoading, setIsLoading, setTransaksi, data, ...props }) {
  const [display, changeDisplay] = useState('hide');
  const [sumMonth, setSumMonth] = useState([]);

  // console.log(+data?.totalAll);

  const authCtx = useContext(AuthContext);

  // const total = sumMonth
  //   .filter(month => month.amount)
  //   .map(month => +month.amount)
  //   .reduce((acc, curr) => acc + curr, 0);

  // console.log(sumMonth, data.total);

  useEffect(() => {
    sendData('transaksi/permonth', null, 'GET')
      .then(res => {
        // console.log(res);
        setSumMonth(res);
      })
      .catch(_ => console.error('server error'));
  }, []);

  return (
    <>
      <Flex
        w={[
          '100%',
          '100%',
          !authCtx.isAdmin ? '80%' : null,
          !authCtx.isAdmin ? '80%' : null,
        ]}
        p="3%"
        flexDir="column"
        overflow="auto"
        h="100vh"
      >
        <Text color="gray" fontSize="sm">
          Saldo
        </Text>
        <Text fontWeight="bold" fontSize="2xl" my={3}>
          {`Rp. ${formatCurr(+data?.totalAll || 0)}`}
          {/* {`Rp. ${formatCurr(total || 0)}`} */}
        </Text>
        <MyChart data={sumMonth} />
        <Flex justifyContent="space-between" mt={8}>
          <Stack direction="column" spacing="0.5">
            <Heading as="h2" size="lg" letterSpacing="tight">
              Transaksi
            </Heading>
            {!props.filterDate.start && (
              <Text fontSize="sm">{formatDate(Date.now(), false)}</Text>
            )}
            {props.filterDate.start && (
              <Text fontSize="xs">{`${formatDate(
                props.filterDate.start
              )} - ${formatDate(props.filterDate.end)}`}</Text>
            )}
          </Stack>
          <Search setSearch={props.setSearch} />
          <MyPopover
            icon={<FiCalendar />}
            setLoading={setIsLoading}
            setTransaksi={setTransaksi}
            setFilterDate={props.setFilterDate}
          />
        </Flex>
        <Flex flexDir="column">
          <Flex overflow="auto">
            <Table variant="unstyled" mt={4}>
              <Thead>
                <Tr color="gray">
                  <Th></Th>
                  {authCtx.isAdmin && (
                    <>
                      <Th>nama</Th>
                      <Th>no.trans</Th>
                    </>
                  )}
                  <Th>Kategori</Th>
                  <Th isNumeric>Jumlah</Th>
                  <Th>Status</Th>
                  {authCtx.isAdmin && <Th>aksi</Th>}
                </Tr>
              </Thead>

              <Tbody>
                {data.transaksi?.map(data => (
                  <Tr key={data.id}>
                    <Td>
                      <Flex align="center">
                        <Avatar size="sm" mr={2} src="kaaba.png" />
                        <Flex flexDir="column">
                          <Heading size="sm" letterSpacing="tight">
                            {data?.name || '-'}
                          </Heading>
                          <Text fontSize="sm" color="gray">
                            {formatDate(data.created_at)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    {authCtx.isAdmin && (
                      <>
                        <Td>{data?.user?.name}</Td>
                        <Td>{data?.no_trans?.toUpperCase()}</Td>
                      </>
                    )}
                    <Td>{data.category?.toUpperCase()}</Td>
                    <Td isNumeric>{`${
                      Math.sign(data.amount) === 1 ? '+Rp' : 'Rp'
                    }  ${formatCurr(data.amount)}`}</Td>
                    <Td>
                      <Badge
                        colorScheme={
                          data.state?.name === 'Approved'
                            ? 'green'
                            : data.state?.name === 'Pending'
                            ? 'yellow'
                            : 'red'
                        }
                      >
                        {data.state?.name || 'Err'}
                      </Badge>
                    </Td>
                    {authCtx.isAdmin && data.state?.name === 'Pending' && (
                      <Td>
                        <ActionButton data={data} trigger={props.setTrigger} />
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
          <Flex align="center">
            <Divider />
            <IconButton
              icon={display === 'show' ? <FiChevronUp /> : <FiChevronDown />}
              onClick={() => {
                if (display === 'show') {
                  changeDisplay('none');
                } else {
                  changeDisplay('show');
                }
              }}
            />
            <Divider />
          </Flex>
        </Flex>
      </Flex>

      {!authCtx.isAdmin && (
        <Right
          // total={formatCurr(total)}
          totalAll={formatCurr(+data?.totalAll)}
          trigger={props.setTrigger}
          card={authCtx.card}
        />
      )}
    </>
  );
}

export default Main;
